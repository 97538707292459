import type SplitIO from '@splitsoftware/splitio/types/splitio'
import type { Attributes } from '@splitsoftware/splitio/types/splitio'

// TYPES --------------------

export type SplitConfig = {
  authorizationKey: string
  trafficKey: string
  trafficType: string
}

export type FlagDefinition = Record<string, Flag<FlagValue>>

export type Flag<T extends FlagValue> = {
  /** The value Split should provide when the app is running locally. */
  local: T
  /**
   * The value this flag should have when no Split value is available.
   *
   * Used when:
   * 1) Split SDK has not been initialized yet
   * 2) Split SDK returns 'control' for the flag
   * 3) An error occurs
   */
  fallback: T
}

/**
 * An object with key-value pairs for each feature flag specified in the
 * FeatureFlagDefinition.
 *
 * E.g:
 * {
 *   showHelloWorld: boolean,
 *   someStringFlag: string,
 * }
 */
export type FeatureFlagValues<T extends FlagDefinition> = {
  [Properties in FlagKey<T>]: FlagType<T[Properties]>
}

/**
 * The types of value our flags can have. To add a new type, add it here and
 * update 'converters.ts' to handle conversions to/from the new type.
 */
export type FlagValue = string | boolean

// CLIENT --------------------

/**
 * A client for interacting with Split feature flags.
 *
 * Sample usage:
 *
 * ```typescript
 * const flagDefinitions = {
 *  exampleBooleanFlag: {
 *   local: true,
 *   fallback: false,
 *  },
 *  exampleStringFlag: {
 *   local: 'local',
 *   fallback: 'fallback',
 *  },
 * }
 *
 * const config = {
 *   authorizationKey: 'your Split API key',
 *   trafficKey: 'traffic key, e.g. user email',
 *   trafficType: 'traffic type, e.g. user',
 * }
 *
 * const client = await ThymeSplitClient.createSplitClient(flagDefinitions, config)
 *
 * const flags = client.getFlagValues()
 * console.log(flags.exampleBooleanFlag) // true
 *
 * // Listen for updates to the flags, if you are keeping a local copy
 * client.onValuesUpdated(() => {
 *  console.log('Flags updated:', client.getFlagValues())
 * })
 *
 * ```
 */
export class ThymeSplitClient<T extends FlagDefinition> {
  private constructor(
    private flagDefinitions: T,
    private client: SplitIO.IBrowserClient
  ) {}

  static getFallbackValues<T extends FlagDefinition>(
    flagDefinitions: T
  ): FeatureFlagValues<T> {
    return Object.fromEntries(
      Object.keys(flagDefinitions).map((key) => [
        key,
        flagDefinitions[key].fallback,
      ])
    ) as FeatureFlagValues<T>
  }

  static getLocalSplitValues<T extends FlagDefinition>(
    flagDefinitions: T
  ): { [key: string]: SplitIO.Treatment } {
    return Object.fromEntries(
      Object.keys(flagDefinitions).map((key) => [
        key,
        flagValueToSplitTreatment(flagDefinitions[key].local),
      ])
    )
  }

  static async createSplitClient<T extends FlagDefinition>(
    flagDefinitions: T,
    config: SplitConfig,
    attributes?: Attributes
  ): Promise<ThymeSplitClient<T>> {
    const features = ThymeSplitClient.getLocalSplitValues(flagDefinitions)

    // WARNING: This is a large dependency! Imported inline so it's only
    // pulled in when we actually init this module, rather than
    // upon app startup. This improves bundle code splitting
    // and initial page load time
    const { SplitFactory } = await import('@splitsoftware/splitio')
    const factory: SplitIO.IBrowserSDK = SplitFactory({
      core: {
        authorizationKey: config.authorizationKey,
        key: config.trafficKey,
        trafficType: config.trafficType,
      },
      features,
      debug: 'WARN',
    })

    const client = factory.client()

    if (attributes !== undefined) {
      client.setAttributes(attributes)
    }

    await client.ready()

    return new ThymeSplitClient(flagDefinitions, client)
  }

  getKeys(): string[] {
    return Object.keys(this.flagDefinitions)
  }

  getFlagConfig(key: FlagKey<T>): object | null {
    const treatmentResult: SplitIO.TreatmentWithConfig =
      this.client.getTreatmentWithConfig(key)
    if (treatmentResult.config) {
      // @ts-ignore
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return JSON.parse(treatmentResult.config)
    }
    return null
  }

  getFlagValues(attributes?: SplitIO.Attributes): FeatureFlagValues<T> {
    const treatments = this.client.getTreatments(this.getKeys(), attributes)

    return {
      ...ThymeSplitClient.getFallbackValues(this.flagDefinitions),
      ...flagValuesFromSplitTreatments(treatments, this.flagDefinitions),
    }
  }

  onValuesUpdated(callbackFn: () => void) {
    this.client.on(this.client.Event.SDK_UPDATE, callbackFn)
  }

  async destroy() {
    await this.client.destroy()
  }
}

// HELPER TYPES --------------------

/** A property on FeatureFlagDefinition, i.e. a flag name */
export type FlagKey<T extends FlagDefinition> = string & keyof T

/**
 * Type helper which resolves to the Flag's type parameter, i.e. Flag<boolean>
 * => boolean
 */
type FlagType<T extends Flag<any>> = T extends Flag<infer U> ? U : never

/**
 * Defines constants for working with common Split values.
 */
enum SplitTreatmentValue {
  ON = 'on',
  OFF = 'off',
  /**
   * Control is a reserved value in Split and usually indicates something has
   * gone wrong.
   * See https://help.split.io/hc/en-us/articles/360020528072-Control-treatment
   */
  CONTROL = 'control',
}

// HELPER FUNCTIONS --------------------

/**
 *
 * @param key
 * @param flagDefinitions
 */
function isFlagKey<T extends FlagDefinition>(
  key: string,
  flagDefinitions: T
): key is FlagKey<T> {
  return flagDefinitions[key as keyof T] !== undefined
}

/**
 *
 * @param flagKey
 * @param flagDefinitions
 */
function isBooleanFlagKey<T extends FlagDefinition>(
  flagKey: FlagKey<T>,
  flagDefinitions: T
): boolean {
  return typeof flagDefinitions[flagKey].local === 'boolean'
}

/**
 * Converts a FlagValue to a Split Treatment (string).
 *
 * Add conversion logic here to expand the supported FlagValue types.
 * @param flagValue
 */
export function flagValueToSplitTreatment(
  flagValue: FlagValue
): SplitIO.Treatment {
  if (typeof flagValue === 'boolean') {
    return flagValue ? SplitTreatmentValue.ON : SplitTreatmentValue.OFF
  }
  // String value
  return flagValue
}

/**
 * Converts from Split Treatments string key-value pairs to FeatureFlagValues.
 *
 * Add conversion logic here to expand the supported FlagValue types.
 * @param treatments
 * @param flagDefinitions
 */
export function flagValuesFromSplitTreatments<T extends FlagDefinition>(
  treatments: SplitIO.Treatments,
  flagDefinitions: T
): Partial<FeatureFlagValues<T>> {
  const updatedFlags: Partial<FeatureFlagValues<T>> = {}
  for (const [k, v] of Object.entries(treatments)) {
    if (!isFlagKey(k, flagDefinitions)) {
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      console.error(`Cannot convert unexpected Split key: ${k}`)
      continue
    }

    if (isBooleanFlagKey(k, flagDefinitions)) {
      // We have a boolean flag. Allowed values are ON and OFF. Anything
      // else is considered unexpected and will use the fallback.
      switch (v) {
        case SplitTreatmentValue.ON:
          // @ts-ignore
          updatedFlags[k] = true
          break
        case SplitTreatmentValue.OFF:
          // @ts-ignore
          updatedFlags[k] = false
          break
        default:
          // @ts-ignore
          updatedFlags[k] = flagDefinitions[k].fallback
      }
    } else {
      // We have a string flag. Treat CONTROL as an unexpected value and
      // everything else as okay.
      // @ts-ignore
      updatedFlags[k] =
        v === SplitTreatmentValue.CONTROL ? flagDefinitions[k].fallback : v
    }
  }
  return updatedFlags
}
