import { Auth0Plugin, createAuth0 } from '@auth0/auth0-vue'
import { useConfigStore } from '@/legacy/store/modules/config'
import { Config } from '@/legacy/types/api/config'

/**
 *
 */
function _getConfig(): Config {
  const configStore = useConfigStore()
  if (configStore.configVals == null) {
    throw new Error('Cannot use auth before config is loaded')
  }
  return configStore.configVals
}

/**
 *
 */
function _authCallbackUrl(): string {
  return `${window.location.origin}/auth/callback`
}

let _auth0: Auth0Plugin | null = null
/**
 *
 */
export function auth0(): Auth0Plugin {
  if (!_auth0) {
    const config = _getConfig()
    _auth0 = createAuth0(
      {
        domain: config.auth0.domain,
        clientId: config.auth0.clientId,
        useRefreshTokens: true,
        useRefreshTokensFallback: false,
        // don't store in local storage unless we find a secure solution for xss attacks
        // auth0.com/docs/secure/security-guidance/data-security/token-storage#browser-local-storage-scenarios
        // cacheLocation: 'localstorage',
      },
      {
        // Skip this automatically, since we handle it in the AuthCallback component
        skipRedirectCallback: true,
        errorPath: '/error',
      }
    )
  }
  return _auth0
}

/**
 *
 * @param targetUrl
 */
export async function loginWithRedirect(targetUrl?: string): Promise<void> {
  document.title = 'Thyme Box - Authorizing...'

  const params = new URLSearchParams(window.location.search)
  if (params.get('error_description')) {
    console.log(params.get('error_description'))
    return
  }

  const config = _getConfig()
  await auth0().loginWithRedirect({
    authorizationParams: {
      redirect_uri: _authCallbackUrl(),
      audience: config.auth0.apiAudience,
      invitation: params.get('invitation') ?? undefined,
      // There is no organizationId in E2E tests
      ...(config.auth0.organizationId
        ? { organization: config.auth0.organizationId }
        : {}),
    },
    appState: { target: targetUrl ?? window.location.href },
  })
}

/**
 *
 */
export async function getAccessToken(): Promise<string> {
  try {
    return await auth0().getAccessTokenSilently({
      authorizationParams: {
        redirect_uri: _authCallbackUrl(),
        audience: _getConfig().auth0.apiAudience,
      },
    })
  } catch (e) {
    console.log('failed to get access token', e)

    // May have reached refresh token expiration. Send them to login.
    await loginWithRedirect()
    return ''
  }
}
