import { Nullable } from 'vitest'
import { Person } from '@/legacy/types/entities/people'
import { PickWithOptionality } from '@/legacy/types/global/base'
import { Turn } from '../communications/texting'
import { DateTimeUTC } from '../global/dates'

// TYPES --------------------
export type Digit = { digit: string; help?: string; secondary?: boolean }

export type PhoneNumberNameAndType = {
  name: string
  type: string
}

export type PhoneNumberNameMap = {
  [key: string]: PhoneNumberNameAndType[]
}

export type PhoneNumber = {
  countryCode: string
  messagingOptInStatus: boolean | null
  phoneNumber: string
  phoneNumberId: string
  extension?: string | null
  person?: Person
  type?: PhoneNumberType | null
}

export type MapEntityPhoneNumber = {
  mapEntityPhoneNumberId: string
  entityId: string
  phoneNumberId: string
  isPrimary: boolean
  note?: string
  phoneNumber: PhoneNumber
}

export type MapEntityPhoneNumberPersonSms = {
  person: Person

  relationshipToPatient?: Nullable<string>

  communicationId?: string
  twilioConversationIds?: string[]
  turn?: Turn
  updatedAt?: DateTimeUTC
  people?: Person[]

  lastUpdateDate?: DateTimeUTC
  lastTextMessage?: string
  lastTextMessageAuthor?: string | null
} & MapEntityPhoneNumber

export type PatientPhoneNumberForm = {
  phoneNumber: string
  isPrimary: boolean
  messagingOptInStatus: boolean | null
  countryCode: string
  note: string
}

export type CreatePhoneNumberRequest = PickWithOptionality<
  PhoneNumber,
  (typeof createPhoneNumberRequiredFields)[number],
  (typeof createPhoneNumberOptionalFields)[number]
>

export type CreateEntityPhoneNumberRequest = {
  phoneNumber: CreatePhoneNumberRequest
  isPrimary: boolean | null
  note: string | null
}

export type CreateEntityPhoneNumberResponse = MapEntityPhoneNumber

// INTERFACES --------------------
export interface DialOptions {
  phoneNumber: string
  outboundNumber?: PhoneNumber | null
}

// ENUMS --------------------
export enum PhoneNumberType {
  LANDLINE = 'landline',
  MOBILE = 'mobile',
  VOIP = 'voip',
  OTHER = 'other',
}

// CONSTANTS --------------------
export const CURRENT_MEMBER_STR = 'Current member'

export const createPhoneNumberRequiredFields = [
  'countryCode',
  'phoneNumber',
] as const

export const createPhoneNumberOptionalFields = ['messagingOptInStatus'] as const

export const digits: Digit[] = [
  { digit: '1' },
  { digit: '2', help: 'abc' },
  { digit: '3', help: 'def' },
  { digit: '4', help: 'ghi' },
  { digit: '5', help: 'jkl' },
  { digit: '6', help: 'mno' },
  { digit: '7', help: 'pqrs' },
  { digit: '8', help: 'tuv' },
  { digit: '9', help: 'wxyz' },
  { digit: '#', secondary: true },
  { digit: '0' },
  { digit: '*', secondary: true },
]
