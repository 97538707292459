import { DropdownKV } from '@thyme/nashville/src/types/dropdowns'
import {
  Filter,
  FilterMetaType,
  FilterModalOptions,
  FilterType,
  acuityScoreFilterStrings,
  dateFilterStrings,
  multiValFilterIndicator,
  completedDateFilterStrings,
} from '@thyme/nashville/src/types/tables'
import startCase from 'lodash/startCase'
import values from 'lodash/values'

import { storeToRefs } from 'pinia'
import {
  arraysToDropdownOptions,
  stringsToDropdownOptions,
} from '@/legacy/libs/dropdowns'
import { humanizeRole } from '@/legacy/libs/entity'
import { useContractingEntitiesApi } from '@/legacy/store/catalog/contractingEntities'
import { usePayerApi } from '@/legacy/store/modules/payers'
import { useProfileStore } from '@/legacy/store/modules/profile'
import { tierFilterOptions } from '@/legacy/types/acuity'
import { USState } from '@/legacy/types/entities/entities'
import { Language } from '@/legacy/types/entities/people'
import { SubtaskStatuses } from '@/legacy/types/pathways/pathways'
import {
  ScheduledCallSubtasks,
  SubtaskPriority,
  SubtaskStatus,
  SubtaskVisibility,
  scheduledCallEnumVal,
  subtaskTypeFilterIndicator,
  subtaskTypeKelValMap,
  subtaskVisibilityEnumVal,
} from '@/legacy/types/pathways/subtasks'
import { Payer } from '@/legacy/types/patients/insurances'
import {
  programStatusOptions,
  treatmentStatusOptions,
} from '@/legacy/types/patients/patients'
import { EnrollmentSegment } from '@/legacy/types/patients/prioritySegments'
import {
  providerAndPracticeList,
  ProvidersToPractice,
} from '@/legacy/types/providers'

const defaultStatusFilters = [
  SubtaskStatus.OPEN_UNASSIGNED,
  SubtaskStatus.OPEN_ASSIGNED,
  SubtaskStatus.IN_PROGRESS,
  SubtaskStatus.BLOCKED,
]

export const subtaskStatusChoices = Object.values(SubtaskStatuses)
  .map(({ value, text }: { text: string; icon: string; value: string }) => {
    return {
      value: value,
      label: text,
    } as DropdownKV
  })
  .filter((arr) => arr.value !== SubtaskStatus.REATTEMPT)
const treatmentStatusSelectOptions = treatmentStatusOptions.map((m) => {
  return {
    value: m.key,
    label: m.display,
  } as DropdownKV
})
const programStatusSelectOptions = programStatusOptions.map((m) => {
  return {
    value: m.key,
    label: m.display,
  } as DropdownKV
})

const subtaskVisibilityChoices = Object.values(SubtaskVisibility).map((m) => {
  return {
    value: m,
    label: subtaskVisibilityEnumVal[m],
  } as DropdownKV
})
const scheduledCallChoices = Object.values(ScheduledCallSubtasks).map((m) => {
  return {
    value: m,
    label: scheduledCallEnumVal[m],
  } as DropdownKV
})
const memberStateChoices = Object.entries(USState).map(
  ([abbreviation, fullName]) => {
    return {
      value: abbreviation,
      label: fullName,
    } as DropdownKV
  }
)
export const subtaskVisibilityFilter: Filter = {
  value: null,
  matchMode: undefined,
  modalOptions: {
    label: 'Subtask Visibility',
    type: FilterType.Select,
    options: subtaskVisibilityChoices,
  },
}
export const scheduledCallFilter: Filter = {
  value: null,
  matchMode: undefined,
  modalOptions: {
    label: 'Show Scheduled Calls',
    type: FilterType.Select,
    options: scheduledCallChoices,
  },
}
export const memberStateFilter: Filter = {
  value: null,
  matchMode: undefined,
  modalOptions: {
    label: 'Member State',
    type: FilterType.Select,
    options: memberStateChoices,
  },
}
export const memberStateMultiselectFilter: Filter = {
  value: null,
  matchMode: undefined,
  modalOptions: {
    label: 'Member State',
    type: FilterType.Multiselect,
    options: memberStateChoices,
  },
}
export const subtaskStatusFilter: Filter = {
  value: defaultStatusFilters,
  matchMode: undefined,
  modalOptions: {
    label: 'Subtask Statuses',
    type: FilterType.Multiselect,
    options: subtaskStatusChoices,
  },
}

export const subtaskStatusFilterV2: Filter = {
  value: [SubtaskStatus.OPEN_ASSIGNED, SubtaskStatus.IN_PROGRESS],
  matchMode: undefined,
  modalOptions: {
    label: 'Subtask Statuses',
    type: FilterType.Multiselect,
    options: Object.values(SubtaskStatuses)
      .map(({ value, text }: { text: string; icon: string; value: string }) => {
        return {
          value: value,
          label: text,
        } as DropdownKV
      })
      .filter(
        (arr) =>
          arr.value === SubtaskStatus.OPEN_ASSIGNED ||
          arr.value === SubtaskStatus.IN_PROGRESS
      ),
  },
}

export const treatmentStatusFilter: Filter = {
  value: [],
  matchMode: undefined,
  modalOptions: {
    label: 'Treatment Statuses',
    type: FilterType.Multiselect,
    options: treatmentStatusSelectOptions,
  },
}

export const programStatusFilter: Filter = {
  value: [],
  matchMode: undefined,
  modalOptions: {
    label: 'Program Statuses',
    type: FilterType.Multiselect,
    options: programStatusSelectOptions,
    search: true,
  },
}

export const tierFilters: FilterMetaType = {
  tier: {
    value: null,
    matchMode: undefined,
    hidden: true,
    modalOptions: {
      label: 'Tier',
      type: FilterType.Select,
      options: arraysToDropdownOptions(tierFilterOptions as string[][]),
      dataLocations: acuityScoreFilterStrings,
    },
  },
  acuityGte: { value: null, matchMode: undefined },
  acuityLte: { value: null, matchMode: undefined },
}

export const dueDateFilters: FilterMetaType = {
  dueDate: {
    value: null,
    matchMode: undefined,
    hidden: true,
    modalOptions: {
      label: 'Due Date',
      type: FilterType.DateRange,
      dataLocations: dateFilterStrings,
    },
  },
  dueAfter: { value: null, matchMode: undefined },
  dueBefore: { value: null, matchMode: undefined },
}

export const onCompletedDateFilters: FilterMetaType = {
  completedDate: {
    value: null,
    matchMode: undefined,
    hidden: true,
    modalOptions: {
      label: 'Completed Date',
      type: FilterType.DateRange,
      dataLocations: completedDateFilterStrings,
    },
  },
  completedOnAfter: { value: null, matchMode: undefined },
  completedOnBefore: { value: null, matchMode: undefined },
}

/**
 *
 */
function getAllPractices() {
  return providerAndPracticeList
    .map((set: ProvidersToPractice) => {
      return { value: set.practice, label: set.practice } as DropdownKV
    })
    .sort((a, b) => a.value.localeCompare(b.value))
    .concat([{ value: 'NULL', label: 'Unaligned' }])
}

export const alignedPracticeFilter: Filter = {
  value: null,
  matchMode: undefined,
  modalOptions: {
    label: 'Aligned Practices',
    type: FilterType.Multiselect,
    options: getAllPractices(),
    search: true,
  },
}

export const languagesFilter: Filter = {
  value: null,
  matchMode: undefined,
  modalOptions: {
    label: 'Languages',
    type: FilterType.Multiselect,
    options: arraysToDropdownOptions(Object.entries(Language)),
  },
}

export const prioritiesFilter: Filter = {
  value: null,
  matchMode: undefined,
  modalOptions: {
    label: 'Priorities',
    type: FilterType.Multiselect,
    options: arraysToDropdownOptions(Object.entries(SubtaskPriority)),
  },
}

export const lobFilter: Filter = {
  value: null,
  matchMode: undefined,
  modalOptions: {
    label: 'LOBs',
    type: FilterType.Multiselect,
    options: stringsToDropdownOptions([
      'Medicare Advantage',
      'Commercial',
      'ACOR',
      'Other',
    ]),
  },
}

export const showHiddenSubtasksFilter: Filter = {
  value: null,
  matchMode: undefined,
  modalOptions: {
    label: 'Include Hidden Subtasks',
    type: FilterType.Toggle,
  },
}

export const callAttemptsFilter: Filter = {
  value: null,
  matchMode: undefined,
  modalOptions: {
    label: 'Call History',
    type: FilterType.Multiselect,
    options: [
      { label: '0 attempts', value: '0' },
      { label: '1 attempts', value: '1' },
      { label: '2 attempts', value: '2' },
      { label: '3+ attempts', value: '3' },
    ],
  },
}

export const enrollmentSegmentFilter: Filter = {
  value: null,
  matchMode: undefined,
  modalOptions: {
    label: 'Enrollment Segment',
    type: FilterType.Multiselect,
    options: [
      {
        label: startCase(EnrollmentSegment.HIGH.toLowerCase()),
        value: EnrollmentSegment.HIGH,
      },
      {
        label: startCase(EnrollmentSegment.MEDIUM.toLowerCase()),
        value: EnrollmentSegment.MEDIUM,
      },
      {
        label: startCase(EnrollmentSegment.LOW.toLowerCase()),
        value: EnrollmentSegment.LOW,
      },
    ],
  },
}

export const setupPayerFilter = () => {
  const { data: payers } = storeToRefs(usePayerApi())
  return {
    value: null,
    modalOptions: {
      label: 'Payers',
      type: FilterType.Multiselect,
      optionsFn: () => {
        if (payers.value) {
          const payersArr: Payer[] = values(payers.value)
          return payersArr.map((payer: Payer) => {
            return {
              value: payer.payerId,
              label: payer.payerName,
            } as DropdownKV
          })
        }
        return []
      },
      search: true,
    },
  } as Filter
}

/**
 * Filter by contracting entity
 * Options populated by all contracting entities from API
 * @returns Filter
 */
export const setupContractingEntityIdsFilter = () => {
  const { data: contractingEntities } = storeToRefs(useContractingEntitiesApi())
  return {
    value: [],
    matchMode: undefined,
    modalOptions: {
      label: 'Contracting Entities',
      type: FilterType.Multiselect,
      optionsFn: () => {
        return contractingEntities.value?.map((ce) => ({
          value: ce?.contractingEntityId,
          label: ce?.displayName,
        }))
      },
    },
  } as Filter
}
export const setupSpecificRoleFilter = () => {
  const { roles } = storeToRefs(useProfileStore())
  return {
    value: [],
    matchMode: undefined,
    modalOptions: {
      label: 'Role',
      type: FilterType.Multiselect,
      optionsFn: () => {
        return roles.value.map((ce: any) => ({
          value: ce,
          label: humanizeRole(ce),
        }))
      },
    },
  } as Filter
}
export const subtaskTypeFilter: Filter = {
  value: null,
  matchMode: undefined,
  modalOptions: {
    label: 'Subtask Type',
    type: FilterType.Multiselect,
    search: true,
    options: arraysToDropdownOptions(
      [
        ['complete_assmnt_mbrenroll', 'Complete Enrollment Assessment'],
        [
          'multi_vals-subtask_type-complete_clinical_onboarding',
          'Complete Clinical Onboarding',
        ],
        ['multi_vals-subtask_type-case_conference', 'Complete Case Conference'],

        [
          'multi_vals-subtask_type-symptom_id_mobile',
          'Complete Symptom ID Assessment',
        ],

        ['complete_assmnt_gvp', 'Complete the GVP assessment'],
        ['schedule_appt_civ', 'Schedule member for Health & Wellness Session'],

        ['complete_assmnt_sos', 'Complete site of service shift assessment'],
        ['review_eligibility', 'Review Eligibility for Thyme Care Services'],

        ['complete_rn_cs_gen', 'Nurse Consult'],
        ['prep_cc_new_rn', 'Case Conference Prep (RN)'],
        ['eom_enrollment_text', 'Send EOM Enrollment Assessment Text'],
        [
          'eom_enrollment_member_form',
          'Member to Complete EOM Enrollment Assessment',
        ],
        ['eom_enrollment_review', 'Review EOM Enrollment Assessment'],
        [
          'multi_vals-subtask_type-prepare_eom_care_plan_emr',
          'Prepare EOM Care Plan in EMR',
        ],
        ['eom_care_plan_call', 'Call to Review EOM Care Plan'],
        ['eom_mail_care_plan', 'Download EMR Care Plan and Mail to patient'],
        ['eom_summary_rpt_1', 'Export navigation summary to EMR'],
        [
          'eom_enrollment_reminder',
          'Send EOM Enrollment Assessment Reminder Text',
        ],

        [
          'multi_vals-subtask_type-complete_assmnt_eddc',
          'Complete ED Discharge Assessment',
        ],
        [
          'multi_vals-subtask_type-complete_assmnt_ipa',
          'Complete the Inpatient Admission Assessment',
        ],
        [
          'multi_vals-subtask_type-complete_assmnt_dc',
          'Complete the Inpatient Discharge Assessment',
        ],
        [
          'multi_vals-subtask_type-complete_assmnt_pacdc',
          'Complete the Post-Acute Care Assessment',
        ],

        [
          'multi_vals-subtask_type-educate',
          'Educate Member about Palliative Care',
        ],
        [
          'multi_vals-subtask_type-complete_assmnt',
          'Complete Non-Clinical Onboarding',
        ],
        [
          'notify_md_pcare',
          'Notify medical director to initiate review for approval to proceed with palliative care referral',
        ],
        [
          'confirm_approval_pcare',
          'Confirm that medical director has approved proceeding with palliative care referral',
        ],
        [
          'outreach_practice_pcare',
          'Outreach practice - Palliative Care referral',
        ],
        ['coordinate_refer_palcare', 'Coordinate referral to Palliative Care'],
        [
          'multi_vals-subtask_type-respond_sms',
          'Respond to Inbound Text Message',
        ],
        ['outreach_fac_toc', 'Outreach to Facility'],
        ['evaluate_adt', 'Evaluate New Utilization'],
        ['respond_tcop_support', 'Respond to TCOP Support'],
        ['respond_tcop_support_toc', 'Respond to TCOP Support TOC'],
        ['outreach_rising_risk', 'Distress Thermometer Assessment'],
        [
          'multi_vals-subtask_type-evaluate_and_assess_symptoms',
          'Evaluate & Assess Member Symptom',
        ],
      ].sort((a, b) => a[1].localeCompare(b[1]))
    ),
  },
}

/*
  This helper function checks an array of string vals passed from
  `checkAndTransformMultiValFilters()` for filtering type and returns
  new array of vals based on mapping used.
  e.g. val = ['multi_vals-subtask_type-educate']
      returned val = ['educate_pcare', 'educate_palcare_gen']
*/
export const transformFilterVal = (val: Array<string>) => {
  let newVals: Array<string> = []
  const newValMappingType = val[0].split('-')[1]

  if (newValMappingType === subtaskTypeFilterIndicator) {
    val.forEach((newValKey: string) => {
      const key = newValKey as keyof typeof subtaskTypeKelValMap
      if (subtaskTypeKelValMap[key]) {
        newVals = newVals.concat(subtaskTypeKelValMap[key])
      }
    })
  }
  return newVals
}

/*
  Example filters input: { ..., filter_subtask_keys: ['multi_vals-subtask_type-educate', 'eom_enrollment_reminder'] }
  Example filters output: { ..., filter_subtask_keys: ['educate_pcare', 'educate_palcare_gen', 'eom_enrollment_reminder'] }

  This function is used to transform filter object values that should
  be multiple values (see `subtaskTypeKelValMap` mapping as example).
  The function first creates a copy of passed filters and checks each
  filter object key's value (should be array and should have string
  values that start with 'multi_val').

  Once 'multi_val' values are identified,
  we take note of any filter  values  we need to keep
  e.g. ['eom_enrollment_reminder', 'multi_vals-subtask_type-educate'] =>
        keep 'eom_enrollment_reminder'

  We then take identified 'multi_val' values and pass it
  to a function that'll transform the 'multi_val' values to mapped values
  e.g. ['multi_vals-subtask_type-educate'] => ['educate_pcare', 'educate_palcare_gen'']

  What is returned at the end of this function should be all original filters
  and their values which are transformed if any of the values indicate that
  it has multiple values mapped to it with `multi-val` string predicate.
*/

export const checkAndTransformMultiValFilters = (filters: any) => {
  const transformedFilters = Object.assign({}, filters)

  for (const [key, val] of Object.entries(filters)) {
    const valIsArray = Array.isArray(val) && val.length
    if (valIsArray) {
      const filtered = (val as Array<string>).filter((filterStrVal: string) =>
        filterStrVal.includes(multiValFilterIndicator)
      )
      if (filtered.length) {
        const keep = val.filter(
          (filterStrVal: string) =>
            !filterStrVal.includes(multiValFilterIndicator)
        )
        const transformedVals = transformFilterVal(filtered)
        transformedFilters[key] = [...keep, ...transformedVals]
      }
    }
  }

  return transformedFilters
}

// Will not work for filters with hidden: true (e.g. acuity score filter)
// This function ensures that have any filters with any preset values
// are defaulted to preset values if filteres are cleared.
export const setFiltersDefaults = (f: any, fm: FilterMetaType) => {
  for (const [filterKey, filterOptions] of Object.entries(fm)) {
    if (filterOptions.modalOptions?.preEnabledOptionsFn) {
      const snakeCaseFilterKey = `filter_${filterKey.replace(
        /[A-Z]/g,
        (letter: string) => `_${letter.toLowerCase()}`
      )}`

      f[snakeCaseFilterKey] = filterOptions.value?.length
        ? filterOptions.value
        : filterOptions.modalOptions.preEnabledOptionsFn()
    }
  }
  return f
}

/**
 *
 * @param modalOpts
 */
export async function loadOptions(modalOpts: FilterModalOptions) {
  const optionsFn = modalOpts.optionsFn
  const preEnabledOptionsFn = modalOpts.preEnabledOptionsFn
  const disabledOptionsFn = modalOpts.disabledOptionsFn

  const options = optionsFn ? await optionsFn() : modalOpts.options
  const preEnabledOptions = preEnabledOptionsFn
    ? preEnabledOptionsFn()
    : modalOpts.preEnabledOptions
  const disabledOptions = disabledOptionsFn
    ? disabledOptionsFn()
    : modalOpts.disabledOptions

  return {
    options,
    preEnabledOptions,
    disabledOptions,
  }
}
