<template>
  <div class="error-wrapper bg-nash-purple200 h-full flex justify-center">
    <div class="card w-1/2 h-fit mt-8">
      <div class="card-content bg-nash-neutral100">
        <div class="content text-center">
          <h1>{{ error }}</h1>
          <p>Please reach out to technical support for assistance.</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { useAuth0 } from '@auth0/auth0-vue'
import { computed } from 'vue'

export default {
  props: {
    message: {
      type: String,
      default: null,
    },
  },
  setup(props) {
    const { error: auth0Error } = useAuth0()

    const error = computed(() => {
      return props.message ?? auth0Error.value ?? 'Unknown Error'
    })

    return {
      error,
    }
  },
}
</script>
