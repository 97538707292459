import {
  DropdownKV,
  LegacyDropdownKV,
} from '@thyme/nashville/src/types/dropdowns'
import { SelectOptions } from '@thyme/nashville/src/types/select'
import capitalize from 'lodash/capitalize'

/**
 * converts arrays of tuples in an object with value + label
 * that can be used in dropdowns
 * @param list
 * @returns List of DropdownKV objects
 */
export function arraysToDropdownOptions(list: string[][]) {
  return list.map((v) => {
    return { value: v[0], label: v[1] } as DropdownKV
  })
}

/**
 *
 * @param list
 */
export function stringsToDropdownOptions(list: string[]) {
  return list.map((v) => {
    return { value: v, label: v } as DropdownKV
  })
}

/**
 *
 * @param list
 */
export function stringsToCapitalizedOptions(list: string[]) {
  return list.map((v) => {
    return { value: v, label: capitalize(v) } as DropdownKV
  })
}

/**
 * check if x is of type string
 * @param x
 */
function isStringArray(x: unknown): x is string[] {
  return typeof x === 'string' || x == null
}
/**
 * check if x is of type DropdownKV
 * @param x
 */
function isDropdownKvArray(x: unknown): x is DropdownKV[] {
  // @ts-ignore
  return x instanceof Object && x.label !== undefined && x.value !== undefined
}

/**
 * convert any valid dropdownOption type into LegacyDropdownKV[]
 * @param options
 */
export function toLegacyDropdownOptions<T = string>(
  options: SelectOptions
): LegacyDropdownKV<T>[] {
  if (!options || !options.length) {
    return []
  }
  if ((options as unknown[]).every(isStringArray)) {
    return (options as string[]).map((v) => [v, v])
  }
  if ((options as unknown[]).every(isDropdownKvArray)) {
    return (options as DropdownKV[]).map((v) => [v.value, v.label])
  }
  return options as LegacyDropdownKV<T>[]
}

/**
 * Sort comparator for reminder owner options
 * Sorts by alphabetical order of `label` param
 * @param a reminder owner option
 * @param b another reminder owner option
 */
export function sortByLabel(a: DropdownKV, b: DropdownKV) {
  return a.label.localeCompare(b.label)
}
