// TYPES --------------------
// valid HTTP method names
export type Method = 'GET' | 'PATCH' | 'POST' | 'PUT' | 'DELETE'

export type RequestOptions = {
  headers?: { [key: string]: string }
  params?: { [key: string]: any }
  body?: { [key: string]: any }
  file?: File | null
  noCache?: boolean
  paramSerializationOptions?: qs.IStringifyOptions
}

// CONSTANTS --------------------
export const NO_CACHE = 'no-cache'
export const TEN_MINUTE_CACHE = 'public, max-age=600'
export const THIRTY_MINUTE_CACHE = 'public, max-age=1800'
