import { AcuityScore, PinnedAcuityScore } from '@/legacy/types/acuity'

import { ApiStatePartial } from '@/legacy/types/api/apiBuilder'
import { IdMap, Normalized } from '@/legacy/types/api/store'
import { CarePod } from '@/legacy/types/carePods'
import { Address } from '@/legacy/types/entities/addresses'
import type { Entity, Label } from '@/legacy/types/entities/entities'
import type { Person } from '@/legacy/types/entities/people'
import { MapEntityPhoneNumber } from '@/legacy/types/entities/phoneNumbers'
import type { Nullable, UUID } from '@/legacy/types/global/base'
import { Timestamps } from '@/legacy/types/global/dates'
import { Contact } from '@/legacy/types/patients/contacts'
import { Insurance } from '@/legacy/types/patients/insurances'
import { PrioritySegment } from './prioritySegments'

/**
 *
 * @param nested
 */
function _nestedStatusMapToFlatList(nested: NestedStringMap): StatusMap[] {
  return Object.keys(nested)
    .map((status) =>
      Object.keys(nested[status]).map((substatus) => {
        return {
          display: nested[status][substatus],
          status: status,
          substatus: substatus,
          key: status + '__' + substatus,
        }
      })
    )
    .flat()
}

// TYPES --------------------
export type Patient = {
  entityId: UUID
  summaryNote: string
  entity: Entity
  person: Person
  alignedPractice: string
  alignedProvider: string
  deceasedDate: Nullable<string>
  programStatus: string
  programSubstatus: string
  treatmentStatus: string
  treatmentSubstatus: string
  treatmentIntent: Nullable<string>
  eomStatus: Nullable<string>
  timezone: Nullable<string>

  insurances?: Insurance[]
  contacts?: Contact[]
  labels?: Label[]
  prioritySegment?: Nullable<PrioritySegment>
  firstEnrollmentDate?: Nullable<string>

  carePodId?: Nullable<UUID>
  carePod?: CarePod
  isPodOverride?: boolean
  carePodOverride?: Nullable<string[]>
  latestAcuityScore?: Nullable<AcuityScore>
  pinnedAcuityScore?: Nullable<PinnedAcuityScore>
  inCareTimestamp?: Nullable<Date>
  memberAppAccess?: Nullable<string>
} & Timestamps

export type NormalizedPatient = Normalized<
  Omit<Patient, 'entity' | 'person'>,
  'insurances'
>

export type PatientState = {
  patient: NormalizedPatient | null
  entity: Normalized<Entity, 'phoneNumbers' | 'addresses'> | null
  person: Person | null
  addresses: IdMap<Address> | null
  phoneNumbers: IdMap<MapEntityPhoneNumber> | null
  insurances: IdMap<Insurance> | null
  contacts: IdMap<Contact> | null
} & ApiStatePartial

export type PatientEmailForm = {
  email: string
}

// INTERFACES --------------------

export interface PatientBasicForm {
  [key: string]: string | Date
  firstName: string
  lastName: string
  preferredName: string
  dateOfBirth: Date
}

export interface PatientExtendedForm {
  [key: string]: string | null
  programStatus: string
  treatmentStatus: string
  phoneNumber: string
  streetAddressLine1: string
  streetAddressLine2: string
  city: string
  state: string
  zip: string
  insurancePayerId: string
  insuranceMemberId: string
  insuranceGroupId: string
  insuranceType: string
  insuranceOtherName: string
  contractingEntityId: string | null
  contractingId: string | null
  carePodId: string
}

export type NestedStringMap = { [id: string]: { [id: string]: string } }

export type StatusMap = {
  display: string
  status: string
  substatus: string
  key: string
}

export type IntentMap = {
  display: string
  key: Nullable<string>
}

// ENUMS --------------------

export enum MemberAppAccess {
  ENABLED_BY_PILOT = 'ENABLED_BY_PILOT',
  DISABLED = 'DISABLED',
  ENABLED_BY_CARE_TEAM = 'ENABLED_BY_CARE_TEAM',
}

export enum FocusTimeProgramStatus {
  IN_CARE = 'in_care',
  ONBOARDING = 'onboarding',
}

// constants
export const programStatusMap: NestedStringMap = {
  ineligible: {
    ineligible: 'Ineligible',
    deceased: 'Ineligible - Deceased',
    unsupported_health_plan: 'Ineligible - Unsupported Health Plan',
    unsupported_geography: 'Ineligible - Unsupported Geography',
    no_qualifying_medication: 'Ineligible - No Qualifying Medication',
    no_qualifying_diagnosis: 'Ineligible - No Qualifying Diagnosis',
    exclusion: 'Ineligible - Exclusion',
    other: 'Ineligible - Other',
  },
  disenrolled: {
    deceased: 'Disenrolled - Deceased',
    opt_out: 'Disenrolled - Opt Out',
    lost_contact: 'Disenrolled - Lost Contact',
    survivorship: 'Disenrolled - Survivorship',
    hospice: 'Disenrolled - Hospice',
    no_longer_attributed: 'Disenrolled - No Longer Attributed',
    recapture_failed: 'Disenrolled - Recapture Failed',
    unsupported_health_plan: 'Disenrolled - Unsupported Health Plan',
    unsupported_geography: 'Disenrolled - Unsupported Geography',
    no_qualifying_medication: 'Disenrolled - No Qualifying Medication',
    no_qualifying_diagnosis: 'Disenrolled - No Qualifying Diagnosis',
    exclusion: 'Disenrolled - Exclusion',
    episode_ended: 'Disenrolled - Episode Ended',
  },
  enrollment: {
    enrollment: 'Enrollment',
    reattempt: 'Enrollment - Reattempt',
    no_usable_phone_number: 'Enrollment - Missing/Inoperable/Wrong Number',
    low_trust: 'Enrollment - Low Trust',
    low_interest: 'Enrollment - Low Interest',
    do_not_call: 'Enrollment - Partner Do Not Call',
    opt_out: 'Enrollment - Member Do Not Call',
  },
  recapture: {
    recapture: 'Recapture',
  },
  in_care: {
    in_care: 'In Care',
    hard_to_reach: 'In Care - Hard to Reach',
  },
  eligibility_review: {
    claims: 'Eligibility Review - Claims',
    network: 'Eligibility Review - Network',
    referral: 'Eligibility Review - Referral',
  },
  offboarding: {
    offboarding: 'Offboarding',
  },
  onboarding: {
    onboarding: 'Onboarding',
    hard_to_reach: 'Onboarding - Hard To Reach',
  },
  rapid_access: {
    rapid_access: 'Access & Early ID',
    hard_to_reach: 'Access & Early ID - Hard to Reach',
  },
  re_entry: {
    re_entry: 'Re-entry',
  },
}
export const programStatusOptions = _nestedStatusMapToFlatList(
  programStatusMap
).sort((a, b) => a.display.localeCompare(b.display))

export const PROGRAM_STATUS_DO_NOT_CALL_KEY = 'do_not_call'
export const PROGRAM_STATUS_ENROLLMENT_KEY = 'enrollment'

export const treatmentStatusMap: NestedStringMap = {
  awaiting_start: {
    awaiting_start: 'Awaiting Start',
  },
  declined: {
    paused: 'Paused',
    never_started: 'Never Started',
  },
  on_treatment: {
    chemo_immuno_or_targeted: 'Chemo, Immuno, or Targeted',
    hormone_only: 'Hormone Only',
  },
  hospice: {
    hospice: 'Hospice',
  },
  treatment_break: {
    treatment_break: 'Treatment Break',
  },
  not_recommended: {
    not_recommended: 'Not Recommended',
  },
  survivorship: {
    survivorship: 'Survivorship',
  },
  surgery: {
    surgery: 'Surgery',
  },
  radiation: {
    radiation: 'Radiation',
  },
  surveillance: {
    surveillance: 'Surveillance',
  },
}

export const treatmentStatusOptions = _nestedStatusMapToFlatList(
  treatmentStatusMap
).sort((a, b) => a.display.localeCompare(b.display))

export const treatmentIntentMap: { [id: string]: string } = {
  null: 'None',
  curative: 'Curative',
  palliative: 'Palliative',
  unknown: 'Unknown',
}

export const treatmentIntentOptions: IntentMap[] = Object.keys(
  treatmentIntentMap
)
  .map((intent) => {
    let key: Nullable<string> = intent
    if (key === 'null') {
      key = null
    }
    return { display: treatmentIntentMap[intent], key: key }
  })
  .sort((a, b) => a.display.localeCompare(b.display))
