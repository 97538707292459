<template>
  <LoadingScreen v-if="showLoadingScreen" />
  <div v-else id="app">
    <template v-if="configVals">
      <!-- The actual app -->
      <TWarningBanner class="print:hidden" />
      <TopNavBar v-if="!hideNav && (isAuthenticated || !pageRequiresAuth)" />

      <!-- using the apiUrl as the key reloads the page on change -->
      <RouterView :key="configVals.apiUrl" :class="pageClasses" />
    </template>
    <ErrorPage v-else class="page" :message="errorMessage" />
  </div>
</template>

<script lang="ts">
import { useAuth0 } from '@auth0/auth0-vue'
import { storeToRefs } from 'pinia'

import { computed, defineComponent, onBeforeUnmount, watch } from 'vue'
import { RouterView, useRoute } from 'vue-router'
import TopNavBar from '@/legacy/components/TopNavBar.vue'
import TWarningBanner from '@/legacy/nashville/menu/TWarningBanner/TWarningBanner.vue'
import ErrorPage from '@/legacy/pages/ErrorPage.vue'
import { useConfigStore } from '@/legacy/store/modules/config'
import { useFlagStore } from '@/legacy/store/modules/flags/flags'
import { useProfileStore } from '@/legacy/store/modules/profile'
import LoadingScreen from '@/pages/Basic/LoadingScreen/LoadingScreen.vue'

export default defineComponent({
  components: {
    TopNavBar,
    TWarningBanner,
    RouterView,
    LoadingScreen,
    ErrorPage,
  },
  setup() {
    const errorMessage =
      'Oops! There was an error loading the application. Please refresh the page to try again.'

    const configStore = useConfigStore()
    const flagStore = useFlagStore()
    const { configVals, isLoading: isConfigLoading } = storeToRefs(configStore)
    const { isLoaded: flagsAreLoaded } = storeToRefs(flagStore)
    const profileStore = useProfileStore()
    const route = useRoute()
    // Initial config fetch.
    void configStore.get()

    const pageRequiresAuth = computed(() => !route.meta.noAuthRequired)
    const hideNav = computed(() => !!route.meta.hideNav)
    const pageClasses = computed(() => {
      const classes = ['h-full']
      // Pages that should take the full page without any scrollability, like a loading screen
      const OVERLAY_PAGES = ['/auth/callback']
      if (!OVERLAY_PAGES.includes(route.path)) {
        classes.push('overflow-scroll')
      }
      return classes
    })

    const auth = useAuth0()
    watch(auth.user, async (newVal) => {
      const val = newVal?.sub ? newVal : null
      await profileStore.updateProfile({ update: val })
    })

    const showLoadingScreen = computed(() => {
      if (isConfigLoading.value) {
        return true
      }
      if (pageRequiresAuth.value) {
        return !auth.isAuthenticated.value || !flagsAreLoaded.value
      }
      return false
    })

    onBeforeUnmount(async () => {
      await flagStore.destroy()
    })

    return {
      isAuthenticated: auth.isAuthenticated,
      configVals,
      pageRequiresAuth,
      hideNav,
      showLoadingScreen,
      pageClasses,
      errorMessage,
    }
  },
})
</script>

<style scoped lang="scss">
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  @apply h-screen w-screen flex-col flex justify-items-stretch;
  // Old Browsers & Firefox
  height: 100vh;
  // Modern Browsers & Mobile
  // Fixes browser height issue on mobile
  // https://stackoverflow.com/a/70048720
  height: 100dvh;
}
</style>
