import { unref } from 'vue'
import { RouteLocation } from 'vue-router'
import { auth0, loginWithRedirect } from '@/auth'

/**
 *
 * @param to
 */
export async function maybeAuthGuard(to: RouteLocation) {
  if (to.meta.noAuthRequired) {
    // the loading screen shows on first-entry to application
    // this hides it when the first viewed page is one that doesn't require auth
    return true
  }

  const client = auth0()
  if (!unref(client.isAuthenticated)) {
    // Try to silently auth if we can
    await client.checkSession()
  }
  if (unref(client.isAuthenticated)) {
    return true
  }
  await loginWithRedirect(to.fullPath)
  return true
}
