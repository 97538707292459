import { AonPracticesToProviders } from './aonProviders'

// INTERFACES --------------------
export interface ProvidersToPractice {
  providers: string[]
  practice: string
}

// CONSTANTS --------------------
/*
  NOTE:
  The provider data in the DB is different from the data below,
  (LASTNAME, MD?, FIRSTNAME). Not a problem for now til we have a more formal data model for provider/practice.
*/
export const providerAndPracticeList: ProvidersToPractice[] = [
  {
    practice: 'Affiliated Oncologists',
    providers: [
      'Abdullah Malas',
      'Alfred Guirguis',
      'Amar Hamad',
      'Bassem Chaar',
      'Carrie Mcilwain',
      'Corey Foster',
      'Elke Aippersbach',
      'Faisal Vali',
      'Ghassan Zalzaleh',
      'Harsha Varadhi',
      'Hyuk Kang',
      'Jayanthi Ramadurai',
      'Mauna Pandya',
      'Mouhammed Kelta',
      'Mufaddal Hamadeh',
      'Nikki Neubauer',
      'Paul Crossan',
      'Rami Haddad',
      'Shadi Latta',
      'Steven Engel',
      'Vasia Ahmed',
      'Wasif Shirazi',
    ],
  },
  {
    practice: 'Alliance Cancer Specialists',
    providers: [
      'Aarti Shevade',
      'Alexander Ostrovsky',
      'Allen Terzian',
      'Anjana Ranganathan',
      'Anthony Magdalinski',
      'Arezoo Ghaneie',
      'Benjamin Jacobs',
      'Brian Wojciechowski',
      'Daniel Lebovic',
      'Deric Savior',
      'Erik Zeger',
      'Felice Lepar',
      'Frederick Dold',
      'Howard Zipin',
      'James Spears',
      'Jason Damsker',
      'Jessica Berman',
      'Joseph Potz',
      'Julianne Hibbs',
      'Krista Isaac',
      'Lee Bogart',
      'Lorraine Dougherty',
      'Marc Chernoff',
      'Michael Seidman',
      'Mitchell Alden',
      'Moshe Chasky',
      'Peter Pickens',
      'Rashmi Sanjay',
      'Robert Reilly',
      'Sarah Goodyear',
      'Sramila Aithal',
      'Thomas Peacock',
      'Won Chang',
      'Zonera Ali',
    ],
  },
  {
    practice: 'Astera Cancer Care',
    providers: [
      'Aamer Farooq',
      'Aamir Zaman',
      'Amit Patel',
      'Andrei Dobrescu',
      'Andrew Greenberg',
      'Aysha Khalid',
      'Beiqing Pan',
      'Brian Canavan',
      'Brian Chon',
      'Bruce Wallach',
      'Bruno Fang',
      'Craig Lampert',
      'Damanjit Ghuman',
      'Darren Sachs',
      'David Richards',
      'Edward Licitra',
      'Edward Soffen',
      'Ellen Ronnen',
      'Eugene Shostak',
      'Giuseppe Condemi',
      'Henry Tsai',
      'Hiral Fontanilla',
      'Jae Lee',
      'James Salwitz',
      'Jieqi Liu',
      'Jin Lee',
      'Joel Alcid',
      'John Baumann',
      'Jonathan Rosenbluth',
      'Joseph Pepek',
      'Kathleen Toomey',
      'Lisa Hopkins',
      'Maqsood Amjad',
      'Marcus Porcelli',
      'Maryann Curiba',
      'Michael Nissenblatt',
      'Mohammad Kazemi',
      'Neesha Rodrigues',
      'Philip Pancari',
      'Phillip Reid',
      'Richard Schuman',
      'Robert Cardinale',
      'Robert Fein',
      'Robert Gelfand',
      'Sameer Desai',
      'Seeta Trivedi',
      'Steven Young',
      'Sundus Abbasi',
      'Susan Mcmanus',
      'Todd Flannery',
      'Vadim Zarubin',
    ],
  },
  {
    practice: 'Cancer & Hematology Centers',
    providers: [
      'Amy Vander Woude',
      'Andrew Sochacki',
      'Brett Brinker',
      'Britni Souther Mashni',
      'Colin Hardin',
      'Eric Batts',
      'Eric Santos',
      'Erin Pettijohn',
      'Frances Wong',
      'Haritha Reddy',
      'Jared Knol',
      'Kathleen Yost',
      'Kathrine Cooper',
      'Kathryn Alguire',
      'Kelly Parling-Lynch',
      'Latha Sree Polavaram',
      'Leigh Seamon',
      'Manish Sharma',
      'Mark Campbell',
      'Michael Zakem',
      'Nehal Lakhani',
      'Rizwan Danish',
      'Sreenivasa Chandana',
      'Thomas Gribbin',
      'William Fabricius',
      'Yuanbin Chen',
      'Zabila Saeed',
    ],
  },
  {
    practice: 'Central Georgia Cancer Care',
    providers: ['Bradley Sumrall', 'Harry Moore', 'Linda Hendricks'],
  },
  {
    practice: 'Hope & Healing Cancer Services',
    providers: ['Ellen Gustafson', 'Leela Rao', 'Srilata Gundala'],
  },
  {
    practice: 'Illinois Cancer Specialists',
    providers: [
      'Adam Dickler',
      'Apurva Desai',
      'Bety Ciobanu',
      'Bozena Witek',
      'David Hakimian',
      'Destry Elms',
      'Fatema Esmail',
      'Irfan Mirza',
      'John Eklund',
      'Kristen Wendell',
      'Lisa Baddi',
      'Mark Karides',
      'Rajat Malhotra',
      'Rajini Manjunath',
      'Randy Rich',
      'Richard Siegel',
      'Robert Mandal',
      'Sonia Christian',
      'Stanley Nabrinsky',
      'Syed Zahir',
      'Urszula Sobol',
      'Veerpal Singh',
    ],
  },
  {
    practice: 'New Jersey Cancer & Blood Specialists',
    providers: ['Amory Novoselac', 'Giuseppe Condemi', 'Michael Rotman'],
  },
  {
    practice: 'New Jersey Cancer Care',
    providers: [
      'Hafsa Farooq',
      'Hemalatha Vasireddy',
      'James Orsini',
      'John Conti',
      'Leena Bondili',
      'Pawan Gundavaram',
      'Said Saleh',
      'Sana Najib',
      'Sita Yerramalli',
      'Sitamahalakshmi Yerramalli',
    ],
  },
  {
    practice: 'New Mexico Cancer Center',
    providers: [
      'Amyn Alidina',
      'Annette Fontaine',
      'Barbara Mcaneny',
      'Gregg Franklin',
      'Jose Avitia',
      'Katherine Sanchez',
      'Mary Bucci',
      'Robert Lin',
      'Ronald Kubica',
      'Sofia Palacio Cardenas',
      'Utkarsh Shukla',
    ],
  },
  {
    practice: 'New York Cancer & Blood Specialists',
    providers: [
      'Abhirami Vivekanandarajah',
      'Adam Hines',
      'Adriana Guigova',
      'Aleksander Vayntraub',
      'Alexander Zuhoski',
      'Ali Naboush',
      'Amishi Desai',
      'Amory Novoselac',
      'Aref Agheli',
      'Asmat Ullah',
      'Avi Retter',
      'Brenda Panzera',
      'Byung Kim',
      'Daniel Benasher',
      'David Askin',
      'David Chu',
      'David Straus',
      'Deepali Sharma',
      'Dipen Patel',
      'Dwight De Risi',
      'Efat Azizi',
      'Evan Diamond',
      'Fabio Volterra',
      'George Cyriac',
      'Gurmohan Syali',
      'Harry Staszewski',
      'Helen Shum',
      'Jagmohan Kalra',
      'Jahan Aghalar',
      'Jason Naziri',
      'Jean Bustamante',
      'Jeffrey Vacirca',
      'Jennifer Betourney',
      'Jerry George',
      'Jin Guo',
      'Joseph Cirrone',
      'Joseph Safdieh',
      'Joshua Harris',
      'Kalimullah Quadri',
      'Karan Josan',
      'Kevin Sing',
      'Li-Teh Wu',
      'Louis Avvento',
      'Malvi Thakker',
      'Mark Grand',
      'Martin Barnes',
      'Martin Silverstein',
      'Maxim Shulimovich',
      'Meytal Fabrikant',
      'Michael Elias',
      'Nagwa Saleh',
      'Neal Murphy',
      'Nella Shapiro',
      'Niculae Ciobanu',
      'Nolyn Nyatanga',
      'Noshir Dacosta',
      'Patricia Burns',
      'Paul Gliedman',
      'Paul Youn',
      'Purva Sharma',
      'Ramsey Asmar',
      'Regina Jablonski',
      'Reuven Grossman',
      'Richard Zuniga',
      'Rita Weiss',
      'Robert Hendler',
      'Rocco Caruso',
      'Roy Chen',
      'Sadia Riaz',
      'Samir Patel',
      'Sanjeev Jain',
      'Seetha Murukutla',
      'Shahid Nawaz',
      'Shreya Goyal',
      'Sridhar Reddy',
      'Steven Gruenstein',
      'Steven Montana',
      'Talha Shaikh',
      'Tarun Wasil',
      'Triantafillos Fillos',
      'Umangi Patel',
      'Varun Modi',
      'Vernon Wu',
      'Victoria Schuster',
      'William Grace',
      'Yelda Nouri',
    ],
  },
  {
    practice: 'Northwest Oncology and Hematology',
    providers: [
      'Alkarim Tajuddin',
      'Anita Pandey',
      'Bruce Bank',
      'Edwin Priest',
      'Elizabeth Elliott',
      'Erol Yorulmazoglu',
      'Gary Gordon',
      'Gary Kay',
      'Kevin Mckian',
      'Lai Xu',
      'Roberto Montoya Barraza',
      'Ronald Shade',
      'Steven Kanter',
      'Yirong Zhu',
    ],
  },
  {
    practice: 'Oncology Specialists of Charlotte',
    providers: [
      'Jennifer Dallas',
      'Justin Favaro',
      'Kaitlyn O’Keefe',
      'Nasfat Shehadeh',
    ],
  },
  {
    practice: 'Pennsylvania Cancer Specialists & Research Institute',
    providers: [
      'Eugene Hong',
      'Jean Koka',
      'Neal Shah',
      'Raymond Vivacqua',
      'Ronald Swaab',
      'Satish Shah',
      'Tina Khair',
    ],
  },
  {
    practice: 'Regional Cancer Care Associates',
    providers: [
      'Aileen Chen',
      'Andrew Bernstein',
      'Arvind Kumar',
      'Avishek Kumar',
      'Beata Pieczara',
      'Bhavesh Balar',
      'Carl Henningson',
      'Chitra Kumar',
      'Denis Fitzgerald',
      'Ethan Wasserman',
      'Gurpreet Lamba',
      'Hemang Dave',
      'Ian Horkheimer',
      'James Lee',
      'Jeffrey Silberberg',
      'Julianne Childs',
      'Jumana Chatiwala',
      'Kaleem Ahmad',
      'Kenneth Nahum',
      'Kimberly Salwitz',
      'Manmeet Ahluwalia',
      'Maurice Cairoli',
      'May Abdo-Matkiwsky',
      'Nandini Ignatius',
      'Niloo Prasad',
      'Rachel Levenbach',
      'Richard Scher',
      'Robert Tassan',
      'Rujuta Saksena',
      'Seth Berk',
      'Stephen Wallace',
      'Vinod Varki',
    ],
  },
  {
    practice: 'Rittenhouse Hematology Oncology',
    providers: ['Jascha Rubin', 'Rene Rubin'],
  },
  {
    practice: 'Shore',
    providers: ['Tiffany Pompa', 'Vijay Sandilya', 'Vijaykumar Gandhi'],
  },
  {
    practice: 'Southern Oncology-Hematology Associates',
    providers: [
      'Benjamin Negin',
      'Kush Sachdeva',
      'Minesh Nandi',
      'Mylene Go',
      'Omar Al Ustwani',
      'Ramakrishna Sudhindra',
      'Shailja Roy',
      'Tiffany Pompa',
      'Vijay Sandilya',
      'Yasir Khan',
    ],
  },
  {
    practice: 'Summit Health',
    providers: [
      'Alina Hirsh',
      'Andrew Lu',
      'Andrew Wong',
      'Benjamin Freeman',
      'Carol Kornmehl',
      'Charles Kim',
      'David Gallinson',
      'David Schreiber',
      'Diana Addis',
      'Elan Diamond',
      'Glen Gejerman',
      'Jeanette Kovtun',
      'Jennifer Reeder',
      'Karleung Siu',
      'Lalitha Anand',
      'Lisa Mills',
      'Marianna Strakhan',
      'Michael Wax',
      'Neelam Todi',
      'Robert Ivker',
      'Roshini George',
      'Sarada Gurubhagavatula',
      'Scott Hanan',
      'Scott Richard',
      'William Diehl',
      'William Ding',
      'Zubin Bamboat',
    ],
  },
  {
    practice: 'Texas Oncology',
    providers: [
      'Abel Moron',
      'Abhilasha Patel',
      'Abram Trevino',
      'Adarsh Sidda',
      'Aena Patel',
      'Aimee Mackey',
      'Ajay Dubey',
      'Akbar Rizvi',
      'Alan Rodney',
      'Alfredo Santillan-Gomez',
      'Alison Barron',
      'Alison Rome',
      'Allison Dipasquale',
      'Allison Gorrebeeck',
      'Allyson Harroff',
      'Alpa Nick',
      'Alvaro Restrepo',
      'Alyssa Rieber',
      'Ami Patel-Yadav',
      'Amir Faridi',
      'Ana Rojas',
      'Anand Shivnani',
      'Anant Sharma',
      'Ananth Arjunan',
      'Andrae Vandross',
      'Andrew Brenner',
      'Andrew Jackson',
      'Andrew Kovoor',
      'Andrew Lee',
      'Andrew Mccollum',
      'Andrew Paulson',
      'Andrew Shaw',
      'Andrew Whiteley',
      'Angel Mier Hicks',
      'Angela Seda',
      'Anil Bhogaraju',
      'Anil Singh',
      'Anish Meerasahib',
      'Anju Nair',
      'Ann Kurian',
      'Ann-Margaret Ochs',
      'Anna Belcheva',
      'Anna Priebe',
      'Anthony Tolcher',
      'Antoinette Matthews',
      'Anton Melnyk',
      'Antonio Santillan-Gomez',
      'Anu Dham',
      'Anuradha Gupta',
      'Anwar Khurshid',
      'Apryl Mensah',
      'Archana Ganaraj',
      'Arlene Garcia-Soto',
      'Arsenio Lopez',
      'Arun Rao',
      'Arve Gillette',
      'Arvind Bhandari',
      'Asad Dean',
      'Asha Karippot',
      'Ashleigh Porter',
      'Ashok Malani',
      'Ashwani Agarwal',
      'Asif Lakhani',
      'Athira Unnikrishnan',
      'Atisha Manhas',
      'Awad Ahmed',
      'Azmina Patel',
      'Balesh Sharma',
      'Balijepalli Netaji',
      'Barry Brooks',
      'Barry Firstenberg',
      'Barry Wilcox',
      'Bartlomiej Posnik',
      'Benjamin Kitchens',
      'Benjamin West',
      'Berchmans John',
      'Bernard Taylor',
      'Beth Hellerstedt',
      'Bijal Modi',
      'Billie Marek',
      'Bradley Christensen',
      'Bradley Colton',
      'Branden Hsu',
      'Brandon Roane',
      'Brandon Sawyer',
      'Brennen Cheek',
      'Brian Ulrich',
      'Bridget O’Brien',
      'Bruce Fine',
      'Bryan Lin',
      'Caesar Tin-U',
      'Camille Johnson',
      'Carl Chakmakjian',
      'Carl Nuesch',
      'Carlos Encarnacion',
      'Carlos Gonzalez-Angulo',
      'Carlos Rubin De Celis',
      'Carlos Taboada',
      'Carolina Escobar',
      'Caroline Coombs-Skiles',
      'Carolyn Jenks',
      'Carolyn Thomas',
      'Carsten Kampe',
      'Catherine Wu',
      'Cathryn Doughtie',
      'Celeste Wilcox',
      'Chandana Kakani',
      'Charles Connor',
      'Charles Cowey',
      'Charles Kurkul',
      'Charles Lee',
      'Chi Pham',
      'Christine Lee',
      'Christopher Braden',
      'Christopher Lafargue',
      'Christopher Littlejohn',
      'Christopher Maisel',
      'Christopher Stokoe',
      'Claude Denham',
      'Clinton Park',
      'Corinne Jordan',
      'Courtney Sheinbein',
      'Courtney Yau',
      'Cristi Aitelli',
      'Cynthia Osborne',
      'Dai Chu Luu',
      'Daniel Farray-Berges',
      'Danielle Jacobbe',
      'Darren Kocs',
      'Darshan Gandhi',
      'David Barrera',
      'David Dspain',
      'David Friedman',
      'David George',
      'David Gregorio',
      'David Sommerhalder',
      'David Yin',
      'Debra Patt',
      'Deepa Sashital',
      'Deepthi Thotakura',
      'Demetrius Loukas',
      'Dennis Costa',
      'Dhatri Kodali',
      'Dheeraj Reddy',
      'Diaa Osman',
      'Diana Hook',
      'Dina Tebcherany',
      'Donald Richards',
      'Donald Schwarz',
      'Douglas Orr',
      'Dustin Manders',
      'Edsel Hesita',
      'Edward Pearson',
      'Elizabeth Pollard',
      'Emmalind Aponte',
      'Enrique Perez',
      'Eric Meyers',
      'Eric Nadler',
      'Erik Koon',
      'Estil Vance',
      'Eswar Tipirneni',
      'Farhan Mohammad',
      'Gary Lu',
      'Gary Weiss',
      'Gavin Melmed',
      'Gerardo Trillo',
      'Ghanem Daghestani',
      'Gilberto Jimenez-Justiniano',
      'Gladys Minor',
      'Granger Scruggs',
      'Guillermo Lazo-Diaz',
      'Gurjyot Doshi',
      'Habib Ghaddar',
      'Habte Yimer',
      'Haotong Wang',
      'Hareesha Vemuganti',
      'Hari Kalla',
      'Haris Baig',
      'Harold Freeman',
      'Harris Naina',
      'Haskell Kirkpatrick',
      'He Zhu',
      'Heather King',
      'Heather Pulaski',
      'Helen Eshed',
      'Henna Malik',
      'Henrik Illum',
      'Houston Holmes',
      'Hycienth Ahaneku',
      'Ildefonso Rodriguez Rivera',
      'Ines Sanchez-Rivera',
      'Inna Shmerlin',
      'Jacinth Joseph',
      'Jagathi Challagalla',
      'Jairo Olivares',
      'James Mobley',
      'James Petrikas',
      'James Uyeki',
      'James Wilder',
      'Jana Reynolds',
      'Jane Chawla',
      'Janice Mullins',
      'Janice Tomberlin',
      'Jared Sturgeon',
      'Jasmine Gowarty',
      'Jason Melear',
      'Jason Smith',
      'Jay Courtright',
      'Jay Schachner',
      'Jaya Juturi',
      'Jeffery Morton',
      'Jeffrey Greenberg',
      'Jeffrey Lamont',
      'Jeffrey Yorio',
      'Jennifer Brown',
      'Jennifer Hecht',
      'Jennifer Lee',
      'Jennifer Sasaki',
      'Jennifer Wright',
      'Jerry Barker',
      'Jessamy Boyd',
      'Jesse Medellin',
      'Jill Heffernan',
      'Jingdong Su',
      'John Adams',
      'John Hardaway',
      'John Mathews',
      'John O’Connor',
      'John Ogden',
      'John Renshaw',
      'John Thachil',
      'Jonathan Oh',
      'Jonathan Verma',
      'Jordan Buess',
      'Jorge Darcourt',
      'Joseph De La Garza',
      'Joseph Hodges',
      'Joseph Kaczor',
      'Joshua Kilgore',
      'Joshua Rodriguez',
      'Joyce O’Shaughnessy',
      'Juan Herrada',
      'Julie Sprunt',
      'Justin Wray',
      'Karen Cohen',
      'Kartik Konduri',
      'Katherine Wang',
      'Kathleen Shide',
      'Kathryn Hudson',
      'Katrina Birdwell',
      'Kavita Nirmal',
      'Kelash Bajaj',
      'Kerri Perry',
      'Kesha Harris-Henderson',
      'Khalid Mahmood',
      'Khoan Vu',
      'Khusroo Qureshi',
      'Kiran Kancharla',
      'Korie Flippo',
      'Kory Jones',
      'Krishna Alluri',
      'Krishna Patel',
      'Kristi Mcintyre',
      'Kruti Nair',
      'Laith Abushahin',
      'Lakshmi Kannan',
      'Lalan Wilfong',
      'Lalitha Janaki',
      'Larry Frase',
      'Laura Chow',
      'Laura Divine',
      'Laurence Tokaz',
      'Lawrence Foote',
      'Lea Krekow',
      'Leah Zhrebker',
      'Leechuan Andy Chen',
      'Leonardo Forero',
      'Lijo John',
      'Lixin Liao',
      'Loren Rourke',
      'Luis Pineiro',
      'Lute Oas',
      'Lynn Canavan',
      'Lynne Knowles',
      'M.A Athar',
      'Madhava Kanakamedala',
      'Mani Akhtari',
      'Manish Gupta',
      'Manmeet Mangat',
      'Manuel Santiago',
      'Marcelo Boek',
      'Marco Araneda',
      'Maria Aloba',
      'Mark Messing',
      'Mark Saunders',
      'Mark Walberg',
      'Martin Koonsman',
      'Mary Brian',
      'Mary Watters',
      'Matei Socoteanu',
      'Mathew Meeneghan',
      'Mathews George',
      'Meera Shreedhara Vasudha',
      'Meghan Hansen',
      'Melissa Crawley',
      'Melissa Kinney',
      'Micah Burch',
      'Michael Herman',
      'Michael Kasper',
      'Michael Lattanzi',
      'Michael Park',
      'Michael Teneriello',
      'Michela Caruso',
      'Michelina Cairo',
      'Michelle Ashworth',
      'Miguel Albino',
      'Minal Barve',
      'Mirna Abboud',
      'Mohsin Soleja',
      'Monica Diaz',
      'Monte Jones',
      'Moshe Levy',
      'Mrugesh Patel',
      'Muffaddal Morkas',
      'Mukesh Kumar Delvadiya',
      'Nabeel Sarhill',
      'Naga Koteswari Sucha Cheedella',
      'Namrah Siddiq',
      'Nanda Gopalan',
      'Nandita Rao',
      'Nanna Sulai',
      'Narotham Thudi',
      'Nathan Shumway',
      'Naveed Cheema',
      'Naveena Allada',
      'Nayyar Syed',
      'Nebu Koshy',
      'Neeraj Sharma',
      'Nihar Patel',
      'Nikesh Jasani',
      'Nithya Palanisamy',
      'Noah Rozich',
      'Noelle Cloven',
      'Nurul Wahid',
      'Obiageli Ezewuiro',
      'Ofobuike Okani',
      'Oluchukwu Oluoha',
      'Padma Draksharam',
      'Pamela Hayes',
      'Pamela Hoof',
      'Pamela Medellin',
      'Pankaj Khandelwal',
      'Parth Khade',
      'Patrick Griffin',
      'Paul Loar',
      'Paula Ryan',
      'Pavel Levin',
      'Philip Kovoor',
      'Phillip Chae',
      'Praveen Reddy',
      'Praveen Tumula',
      'Preethi John',
      'Preethi Ramachandran',
      'Priyanka Kamath',
      'Puneet Dhillon',
      'Punit Chadha',
      'Racha Halawi',
      'Rachel Kaczynski',
      'Rachel Theriault',
      'Raetasha Dabney',
      'Rajayogesh Davuluri',
      'Ralph Heaven',
      'Rama Koya',
      'Rashad Khan',
      'Raul Portillo',
      'Raulee Morello',
      'Ravi Patel',
      'Reagan Street',
      'Rebecca Barrington',
      'Rebecca Wiatrek',
      'Rebecca Yarborough',
      'Rene Castillo',
      'Renuka Borra',
      'Reva Schneider',
      'Richard Lawson',
      'Ritu Arya',
      'Robert Berryman',
      'Robert Birdwell',
      'Robert Coleman',
      'Robert Mennel',
      'Robert Ruxer',
      'Robin Akins',
      'Rogelio Salinas',
      'Roger Lyons',
      'Ronald Kerr',
      'Ronan Kelly',
      'Roy Paulson',
      'Ruben Saez',
      'Ryan Tierney',
      'Sabarina Ramanathan',
      'Sameeksha Bhama',
      'Samir Mazharuddin',
      'Samir Sejpal',
      'Samuel Geurkink',
      'Sandeep Gill',
      'Sandhya Bejjanki',
      'Sanjay Emandi',
      'Sanjay Oommen',
      'Sanjay Sethi',
      'Sarah Wang',
      'Sasha Davis',
      'Sashidhar Reddy',
      'Scott Celinski',
      'Scott Fleischauer',
      'Scott Mccord',
      'Scott Mckenney',
      'Scott Seals',
      'Scott Stone',
      'Selena Stuart',
      'Seyed Abedi',
      'Shail Dalal',
      'Sharad Jain',
      'Sharon Wilks',
      'Shaun Mckenzie',
      'Sheena Sahota',
      'Shiela Haffar',
      'Shiraj Sen',
      'Shrawan Gaitonde',
      'Shrinivas Diggikar',
      'Shruti Sharma',
      'Shubhada Shrikhande',
      'Silvana Pedra Nobre',
      'Sonal Sethi',
      'Sreedevi Daggubati',
      'Sreenivas Chittoor',
      'Sridevi Juvvadi',
      'Sridhar Beeram',
      'Srikala Meda',
      'Srikar Malireddy',
      'Srinath Sundararajan',
      'Srini Reddy',
      'Srinivas Kodali',
      'Srinivasu Moparty',
      'Srivani Thatikonda',
      'Stacia Pfost',
      'Stephanie Han',
      'Stephen Richey',
      'Steve Perkins',
      'Sucharu Prakash',
      'Sudha Teerdhala',
      'Sumalatha Patibandla',
      'Suneetha Challagundla',
      'Suresh Ratnam',
      'Susan Escudier',
      'Sushma Vemulapalli',
      'Susmita Sakruti',
      'Syed Akhtar',
      'Sylvia Jaramillo',
      'Tammy Roque',
      'Terri Pustilnik',
      'Thanh Nguyen',
      'Thomas Gregory',
      'Thomas Harris',
      'Thomas Hutson',
      'Thuy Le',
      'Timothy Dziuk',
      'Tobenna Nwizu',
      'Tobin Strom',
      'Travis Cox',
      'Tri Le',
      'Tuoc Dao',
      'Tyler Snedden',
      'Vibha Thomas',
      'Victor Mangona',
      'Vijay Gunuganti',
      'Vikas Aurora',
      'Vikas Bhushan',
      'Vipal Durkal',
      'Virginia Kinsella',
      'Vivek Kavadi',
      'Vivian Cline',
      'Wendy Mahone-Johnson',
      'William Esler',
      'William Mcnamara',
      'Yvonne Coyle',
      'Zainab Ilahi',
      'Zehra Kapadia',
    ],
  },
  {
    practice: 'The Center for Cancer and Blood Disorders (TX)',
    providers: [
      'Aaron Laine',
      'Alan Munoz',
      'Alberto Parra',
      'Anh Nguyen',
      'Asim Ahmad',
      'Bibas Reddy',
      'Deepna Jaiswal',
      'Deette Vasques',
      'Geoffrey Gibson',
      'Graham Brown',
      'Henry Xiong',
      'Jeremy Ross',
      'Jivesh Sharma',
      'Mary Ann Skiba',
      'Matthew Carlson',
      'Michael Ross',
      'Mohammad Qasim',
      'Nabila Waheed',
      'Neelema Pinnapureddy',
      'Nicole Bartosh',
      'Prasanthi Ganesa',
      'Ray Page',
      'Revathi Angitapalli',
      'Robyn Young',
      'Rohan Gupta',
      'Shadan Mansoor',
      'Sushama Jasti',
      'Swapna Goday',
      'Talha Riaz',
      'Thomas Heffernan',
      'Tiffany Redfern',
      'Vinaya Potluri',
      'William Jordan',
      'Yaochung Weng',
      'Zachary Brownlee',
    ],
  },
  {
    practice: 'West Cancer Center',
    providers: [
      'Arnel Pallera',
      'Axel Grothey',
      'Bradley Somer',
      'Carmel Verrier',
      'Daniel Vaena',
      'David Portnoy',
      'Eric Wiedower',
      'Gang Tian',
      'Gregory Vidal',
      'Holger Gieschen',
      'Jarvis Reed',
      'Jason Chandler',
      'Jason Porter',
      'Kurt Tauer',
      'Leonard Harris',
      'Liangping Weng',
      'Linda Smiley',
      'Mark Reed',
      'Matthew Ballo',
      'Micah Monaghan',
      'Michael Berry',
      'Michael Farmer',
      'Michael Martin',
      'Michael Ulm',
      'Noam Vanderwalde',
      'Paul Koerner',
      'Ramakrishna Battini',
      'Richard Gilmore',
      'Robert Johnson',
      'Saradasri Wellikoff',
      'Sonia Benn',
      'Syed Sameer Nasir',
      'Sylvia Richey',
      'Thomas Ratliff',
      'Todd Tillmanns',
      'Yuefeng Wang',
    ],
  },
].concat(AonPracticesToProviders)
