import { RouteRecordRaw, createRouter, createWebHistory } from 'vue-router'
import { useProfileStore } from '@/legacy/store/modules/profile'
import { maybeAuthGuard } from './guard'

export type Route = RouteRecordRaw & {
  name: string
  meta: {
    noAuthRequired?: boolean
    title: string
  }
}

const PatientProfile = () => import('@/legacy/pages/PatientProfile.vue')
const patientProfileTabbed = [
  {
    path: '/patient/:patientId/care-plan',
    name: '#patientProfileCarePlan',
    component: PatientProfile,
    meta: {
      title: 'Patient Profile - Care Plan',
    },
  },
  {
    path: '/patient/:patientId/clinical-summary',
    name: '#patientProfileClinicalSummary',
    component: PatientProfile,
    meta: {
      title: 'Patient Profile - Clinical Summary',
    },
  },
  {
    path: '/patient/:patientId/social-summary',
    name: '#patientProfileSocialSummary',
    component: PatientProfile,
    meta: {
      title: 'Patient Profile - Social Summary',
    },
  },
  {
    path: '/patient/:patientId/history',
    name: '#patientProfileHistory',
    component: PatientProfile,
    meta: {
      title: 'Patient Profile - History',
    },
  },
  {
    path: '/patient/:patientId/to-do',
    name: '#patientProfileToDo',
    component: PatientProfile,
    meta: {
      title: 'Patient Profile - To Do',
    },
  },
  {
    path: '/patient/:patientId/symptoms',
    name: '#patientProfileSymptoms',
    component: PatientProfile,
    meta: {
      title: 'Patient Profile - Symptoms',
    },
  },
  {
    path: '/patient/:patientId/texting',
    name: '#patientProfileTexting',
    component: PatientProfile,
    meta: {
      title: 'Patient Profile - Texting',
    },
  },
  {
    path: '/patient/:patientId/medical-context',
    name: '#patientProfileMedicalContext',
    component: PatientProfile,
    meta: {
      title: 'Patient Profile - Medical Context',
    },
  },
  {
    path: '/patient/:patientId/documents',
    name: '#patientProfileDocuments',
    component: PatientProfile,
    meta: {
      title: 'Patient Profile - Documents',
    },
  },
]

export const routes: Route[] = [
  // Routes without auth
  {
    path: '/',
    name: '#home',
    redirect: () => useProfileStore().entryPoint,
    meta: {
      title: '',
      noAuthRequired: true,
    },
  },
  {
    path: '/error',
    name: '#error',
    component: () => import('@/legacy/pages/ErrorPage.vue'),
    meta: {
      title: 'Error',
      noAuthRequired: true,
    },
  },
  {
    path: '/auth/callback',
    name: '#authCallback',
    component: () => import('@/pages/Basic/AuthCallback/AuthCallback.vue'),
    meta: {
      // The auth callback page needs to be public
      // https://github.com/auth0/auth0-vue/blob/main/FAQ.md#4-getting-an-infinite-redirect-loop-between-my-application-and-auth0
      noAuthRequired: true,
      title: 'Authenticating...',
    },
  },

  // All other routes require auth and will redirect to auth0 if not authenticated
  {
    path: '/sandbox',
    name: '#sandbox',
    component: () => import('@/legacy/pages/debug/AdminDebug.vue'),
    meta: {
      title: 'Sandbox',
    },
  },
  {
    path: '/admin/home',
    name: '#adminHome',
    component: () => import('@/legacy/pages/HomePage.vue'),
    meta: {
      title: 'Admin Home',
    },
  },
  {
    path: '/patients',
    name: '#patients',
    component: () => import('@/legacy/pages/PatientsPage.vue'),
    meta: {
      title: 'Members',
    },
  },
  {
    path: '/patients/create',
    name: '#addPatient',
    component: () => import('@/legacy/pages/AddPatient/AddPatient.vue'),
    meta: {
      title: 'Create Member',
    },
  },
  {
    path: '/patient/:patientId/',
    name: '#patientProfile',
    component: PatientProfile,
    meta: {
      title: 'Patient Profile',
    },
  },
  {
    path: '/patient/:patientId/print',
    name: '#patientPrint',
    component: () => import('@/legacy/pages/PatientProfilePrint.vue'),
    meta: {
      title: 'Print Patient Profile',
    },
  },
  {
    path: '/patient/:patientId/report',
    name: '#patientCarePlanReport',
    component: () =>
      import('@/legacy/pages/reporting/PatientCarePlanReport.vue'),
    props: true,
    meta: {
      title: 'Patient Care Plan Report',
    },
  },
  {
    path: '/patient/:patientId/report/v2',
    name: '#patientCarePlanReportV2',
    component: () =>
      import(
        '@/pages/PatientProfile/Tabs/CarePlanTab/PatientCarePlanReport/PatientCarePlanReportV2.vue'
      ),
    props: true,
    meta: {
      title: 'Patient Care Plan Report',
    },
  },
  {
    path: '/my-queue',
    name: '#myQueue',
    component: () => import('@/legacy/pages/MyQueue.vue'),
    meta: {
      title: 'My Queue',
    },
  },
  {
    path: '/tools/unknown-texts',
    name: '#unknownTexts',
    component: () => import('@/legacy/pages/UnknownTextsPage.vue'),
    meta: {
      title: 'Unknown Texts',
    },
  },
  {
    path: '/tools/bulk_edit',
    name: '#bulkEdit',
    component: () => import('@/pages/Tools/BulkEdit/BulkEdit.vue'),
    meta: {
      title: 'Bulk Edit',
    },
  },
  {
    path: '/tools/humana-eligibility-check',
    name: '#HumanaEligibilityCheck',
    component: () =>
      import('@/pages/Tools/HumanaEligibilityCheck/HumanaEligibilityCheck.vue'),
    meta: {
      title: 'Humana Eligibility Check',
    },
  },
  {
    path: '/tools/form-response',
    name: '#FormResponse',
    component: () => import('@/pages/Tools/FormResponse/FormResponse.vue'),
    meta: {
      title: 'Form Response',
    },
  },
  {
    path: '/enrollment-queue',
    name: '#enrollmentQueue',
    component: () => import('@/legacy/pages/EnrollmentQueue.vue'),
    meta: {
      title: 'Enrollment Queue',
    },
  },
  {
    path: '/pod-lead-queue',
    name: '#podLeadQueue',
    component: () => import('@/legacy/pages/PodLeadQueue.vue'),
    meta: {
      title: 'Pod Lead Queue',
    },
  },
  {
    path: '/enrollment-lead-queue',
    name: '#enrollmentLeadQueue',
    component: () => import('@/legacy/pages/EnrollmentLeadQueue.vue'),
    meta: {
      title: 'Enrollment Lead Queue',
    },
  },
  {
    path: '/reminders/all-tasks',
    name: '#allTasks',
    alias: '/reminders/all-reminders',
    component: () => import('@/legacy/pages/AllReminders.vue'),
    meta: {
      title: 'All Tasks',
    },
  },
  {
    path: '/admin/users',
    name: '#adminUsers',
    component: () => import('@/legacy/components/admin/AdminUser.vue'),
    meta: {
      title: 'Admin Users',
    },
  },
  {
    path: '/admin/csv',
    name: '#adminCsvUploads',
    component: () => import('@/legacy/components/admin/AdminCSVUploads.vue'),
    meta: {
      title: 'Admin CSV Uploads',
    },
  },
  {
    path: '/admin/care_pods',
    name: '#adminCarePods',
    component: () => import('@/legacy/components/admin/AdminCarePod.vue'),
    meta: {
      title: 'Admin Care Pods',
    },
  },

  {
    path: '/admin/playbooks/subtasks',
    name: '#adminSubtasks',
    component: () =>
      import('@/legacy/components/admin/playbooks/AdminSubtasksTable.vue'),
    meta: {
      title: 'Admin Subtasks',
    },
  },
  {
    path: '/admin/playbooks/subtasks/:baseTemplateId',
    name: '#adminEditSubtasks',
    component: () =>
      import(
        '@/legacy/pages/pathwaysAdmin/AdminEditSubtasksTemplate/AdminEditSubtasksTemplate.vue'
      ),
    meta: {
      title: 'Admin Edit Subtask Template',
    },
  },

  {
    path: '/admin/playbooks/tasks',
    name: '#adminTasks',
    component: () =>
      import('@/legacy/components/admin/playbooks/AdminTasksTable.vue'),
    meta: {
      title: 'Admin Tasks',
    },
  },
  {
    path: '/admin/playbooks/tasks/:baseTemplateId',
    name: '#adminEditTasks',
    component: () =>
      import(
        '@/legacy/pages/pathwaysAdmin/AdminEditTasksTemplate/AdminEditTasksTemplate.vue'
      ),
    meta: {
      title: 'Admin Edit Task Template',
    },
  },

  {
    path: '/admin/playbooks/playbooks',
    name: '#adminPlaybooks',
    component: () =>
      import('@/legacy/components/admin/playbooks/AdminPathwaysTable.vue'),
    meta: {
      title: 'Admin Playbooks',
    },
  },
  {
    path: '/admin/playbooks/playbooks/:baseTemplateId',
    name: '#adminEditPlaybooks',
    component: () =>
      import(
        '@/legacy/pages/pathwaysAdmin/AdminEditPathwaysTemplate/AdminEditPathwaysTemplate.vue'
      ),
    meta: {
      title: 'Admin Edit Pathway Template',
    },
  },

  {
    path: '/tools/campaigns',
    name: '#bulkCampaigns',
    component: () =>
      import('@/legacy/components/admin/campaigns/CampaignsTable.vue'),
    meta: {
      title: 'Bulk Campaigns',
    },
  },

  {
    path: '/tools/cohorts',
    name: '#cohorts',
    component: () => import('@/pages/Admin/CohortBuilder/CohortBuilder.vue'),
    meta: {
      title: 'Create Cohort',
    },
  },

  {
    path: '/admin/outcomes/edit',
    name: '#adminEditOutcomes',
    component: () => import('@/legacy/pages/AdminOutcomes.vue'),
    meta: {
      title: 'Admin Edit Outcomes',
    },
  },

  {
    path: '/admin/care_plan_goals',
    name: '#adminEditCarePlanGoals',
    component: () =>
      import(
        '@/legacy/components/admin/AdminCarePlanGoals/AdminCarePlanGoals.vue'
      ),
    meta: {
      title: 'Admin Edit Care Plan Goals',
    },
  },

  {
    path: '/admin/care_pod_assignment',
    name: '#adminCarePodAssignment',
    component: () =>
      import('@/legacy/components/admin/AdminCarePodAssignment.vue'),
    meta: {
      title: 'Admin Care Pod Assignments',
    },
  },
  {
    path: '/admin/comorbidity_reference',
    name: '#adminComorbidityReference',
    component: () =>
      import('@/legacy/components/admin/AdminComorbidityReference.vue'),
    meta: {
      title: 'Admin Comorbidity Refererences',
    },
  },
  {
    path: '/admin/document_category',
    name: '#adminDocumentCategories',
    component: () =>
      import('@/legacy/components/admin/AdminDocumentCategories.vue'),
    meta: {
      title: 'Admin Document Categories',
    },
  },
  {
    path: '/admin/outcomes/edit',
    name: '#adminOutcomes',
    component: () => import('@/legacy/pages/AdminOutcomes.vue'),
    meta: {
      title: 'Admin Edit Outcomes',
    },
  },
  {
    path: '/admin/triggers',
    name: '#adminTriggers',
    component: () =>
      import('@/legacy/components/admin/triggers/AdminTriggers.vue'),
    meta: {
      title: 'Admin Autotriggers',
    },
  },
  {
    path: '/admin/meos_charges',
    name: '#adminMeosCharges',
    component: () =>
      import('@/legacy/components/admin/meosCharges/AdminMeosChargesTable.vue'),
    meta: {
      title: 'Admin Meos Charges',
    },
  },
  {
    path: '/admin/thyme_phone_numbers',
    name: '#adminThymePhoneNumbers',
    component: () => import('@/legacy/pages/AdminThymePhoneNumbers.vue'),
    meta: {
      title: 'Admin Thyme Phone Numbers',
    },
  },
  {
    path: '/admin/thyme_phone_number_configurations',
    name: '#adminThymePhoneNumberConfigurations',
    component: () =>
      import('@/legacy/pages/AdminThymePhoneNumberConfigurations.vue'),
    meta: {
      title: 'Admin Thyme Phone Number Configurations',
    },
  },
  ...patientProfileTabbed,
  {
    path: '/:pathMatch(.*)*',
    name: '#notFound',
    component: () => import('@/legacy/pages/404Page.vue'),
    meta: {
      title: 'Page Not Found',
    },
  },
]

const router = createRouter({
  history: createWebHistory(),
  linkActiveClass: 'is-active',
  routes,
})

router.beforeEach(maybeAuthGuard)
router.beforeEach((to, from, next) => {
  const prefix = 'Thyme Box'
  document.title = to.meta.title
    ? `${prefix} - ${String(to.meta.title)}`
    : prefix
  next()
})

export default router
