/**
 * IMPROVEME(SPC-1409) convert to validation schema style
 * This file contains ALL currently shared validation rules for VeeValidate.
 * This is now deprecated in favor of useForm schema validation and will be removed in the future.
 * You can find more pre-built validators at
 * https://vee-validate.logaretm.com/v4/guide/global-validators#vee-validaterules
 */

import { localize } from '@vee-validate/i18n'
import { digits, email, required, url, integer } from '@vee-validate/rules'
import { configure, defineRule } from 'vee-validate'

// CONST
const PHONE_NUMBER_LENGTH = 10
const MAX_MINS = 60
const MIN_MINS = 1

// IMPROVEME(MT-2209): add tests for rules defined below
defineRule('required', required)
defineRule('underscore_only', (value: string) =>
  value && value.match(/^[a-z0-9_]+$/g)
    ? true
    : 'Can only include underscores ("_"), lowercase letters and numbers.'
)

defineRule('minutes', (value: number) =>
  value && integer(value) && value <= MAX_MINS && value >= MIN_MINS
    ? true
    : `Must be an integer no greater than ${MAX_MINS} and no less than ${MIN_MINS}.`
)

defineRule('alphanumeric_underscores_dashes', (value: string) => {
  if (value) {
    return value && value.match(/^[a-z0-9_-]+$/g)
      ? true
      : 'Can only include lowercase letters, numbers, underscores ("_"), and dashes ("-").'
  }
  return true
})

defineRule('phone', (value: unknown) =>
  value && String(value).replace(/\D/g, '').length !== PHONE_NUMBER_LENGTH
    ? 'This field must contain a valid 10 digit phone number.'
    : true
)

defineRule('thyme_email', (value: unknown) =>
  value && email(value) && String(value).endsWith('@thymecare.com')
    ? true
    : 'This field must contain a valid Thyme Care email.'
)

defineRule('digits', digits)
defineRule('email', email)
defineRule('url', url)
defineRule('integer', integer)

defineRule('required_if_any', (value: unknown, fieldVals: unknown[]) =>
  !value && fieldVals.some((x: any) => x)
    ? 'This field is required based on other filled out fields'
    : true
)

configure({
  generateMessage: localize('en', {
    messages: {
      required: 'This field is required.',
      integer: 'This field must be an integer.',
      digits: 'This field must contain exactly 0:{length} digit(s).',
      email: 'This field must contain a valid email address.',
      url: 'This field must contain a valid URL (including http:// or https://).',
    },
  }),
})
