import { THIRTY_MINUTE_CACHE } from '@thyme/libs/src/api/types/api'
import uniqueId from 'lodash/uniqueId'
import { defineStore } from 'pinia'
import { formatNameFromEntity } from '@/legacy/libs/format'
import { safeLookup } from '@/legacy/libs/lookup'
import { idMapTransform } from '@/legacy/libs/store'
import apiStore from '@/legacy/store/modules/apiBuilder'
import {
  transformListAppend,
  transformGetAppend,
} from '@/legacy/store/modules/transforms/transforms'
import { IdMap } from '@/legacy/types/api/store'
import { Entity, EntityRole } from '@/legacy/types/entities/entities'

export type CareTeamChoices = string[][]

export const useStaffStore = defineStore('staff', {
  state: () => ({}),
  getters: {
    careTeamChoices: () => {
      const staff = useStaffApi().data
      if (staff) {
        return Object.keys(staff)
          .map((id) => [id, formatNameFromEntity(safeLookup(id, staff))])
          .sort((a, b) => a[1].localeCompare(b[1]))
      }
      return []
    },
  },
  actions: {
    async fetchStaff() {
      await useStaffApi().listAll({
        params: {
          filter_roles: [EntityRole.CLINICAL, EntityRole.SYSTEM],
          parts: ['person', 'roles'],
        },
        headers: {
          'Cache-Control': THIRTY_MINUTE_CACHE,
        },
      })
    },
  },
})

export const useStaffApi = apiStore<Entity, IdMap<Entity>>(
  'staffApi',
  '/api/entities',
  {
    transformData: (d: { data: Entity[] }): IdMap<Entity> =>
      idMapTransform({}, 'data', 'entityId', d.data),
  }
)

/**
 * return staff name (if available) from staff api given an id
 * returns fallback string if specified and no staff found for given id
 * @param id
 * @param fallback
 */
export function getStaffNameFromId(id: string, fallback?: string) {
  return formatNameFromEntity(safeLookup(id, useStaffApi().data)) || fallback
}

export const createPaginatedStaffApi = (
  uniqueStr: string,
  extraFilters: EntityRole[] | []
) =>
  apiStore<Entity, string[]>(
    `paginatedStaffApi${uniqueId(uniqueStr)}`,
    '/api/entities',
    {
      transformData: transformListAppend<Entity>('entityId'),
      transformDatum: transformGetAppend<Entity>('entityId'),
      params: {
        filter_roles: [EntityRole.CLINICAL, EntityRole.SYSTEM, ...extraFilters],
        parts: ['person', 'roles'],
        sort_by: ['person'],
      },
    }
  )
